html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: MarkPro;
  src: url("MarkPro.e3fbb39a.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: MarkPro;
  src: url("MarkPro-Bold.2dafbde0.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: MarkPro;
  src: url("MarkPro-Extlight.d4217cb1.ttf") format("truetype");
  font-weight: 200;
}

.intro {
  margin-bottom: 60px;
  font-size: 28px;
  font-weight: 200;
  line-height: 1.3;
  display: inline-block;
}

.intro a {
  color: #d70f0a;
}

.spinner {
  width: 70px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  animation: 1.4s ease-in-out infinite both sk-bouncedelay;
  display: inline-block;
}

.spinner .bounce1 {
  animation-delay: -.32s;
}

.spinner .bounce2 {
  animation-delay: -.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

body * {
  box-sizing: border-box;
}

body .content-wrapper {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px 15px;
  display: block;
}

body .content-wrapper .content-container {
  margin: 0 auto;
}

@font-face {
  font-family: MarkPro;
  src: url("MarkPro.e3fbb39a.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: MarkPro;
  src: url("MarkPro-Bold.2dafbde0.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: MarkPro;
  src: url("MarkPro-Extlight.d4217cb1.ttf") format("truetype");
  font-weight: 200;
}

body {
  color: #000;
  font-family: MarkPro;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.1;
}

h1, h2, h3, h4, h5 {
  color: #d70f0a;
}

.headline {
  color: #d70f0a;
  font-family: MarkPro;
  font-weight: 600;
  line-height: 1;
}

.strong {
  font-family: MarkPro;
  font-weight: 600;
}

p {
  line-height: 1.3;
}

.content {
  grid-template-columns: 1fr 1fr;
  gap: 75px;
  display: grid;
}

@media screen and (width <= 890px) {
  .content {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.content .motif-row {
  width: calc(100% - 74px);
  margin-left: 74px;
  margin-right: 10px;
  display: inline-block;
}

.content .motif-row__image {
  width: calc(33% - 5px);
  height: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 3px;
  padding: 5px;
  display: inline-block;
}

.content .motif-row__image.active, .content .motif-row__image:hover {
  background: #f1f4f7;
}

.content .motif-row__image img {
  width: 100%;
}

.content .motif-row span {
  color: #000;
  font-size: 15px;
}

.content .form form {
  visibility: hidden;
  opacity: 0;
  display: none;
}

.content .form__row {
  height: auto;
  display: block;
  position: relative;
}

.content .form__row select[name="motive"], .content .form__row[data-active="1"] .form__shadow {
  display: none;
}

.content .form__row[data-step="4"] {
  cursor: pointer;
}

.content .form__row[data-step="4"] .form__step {
  border-color: #bac1ca;
}

.content .form__row[data-step="4"] .form__step span {
  top: calc(50% + 2px);
}

.content .form__row[data-step="4"] .form__step svg {
  width: 25px;
  height: 25px;
}

.content .form__row[data-step="4"] .form__step svg * {
  fill: #000;
}

.content .form__row[data-step="4"][data-active="1"] .form__step {
  border-color: #d70f0a;
}

.content .form__row[data-step="4"][data-active="1"] .form__step svg {
  width: 25px;
  height: 25px;
}

.content .form__row[data-step="4"][data-active="1"] .form__step svg * {
  fill: #d70f0a;
}

.content .form__row[data-step="4"][data-active="1"] .form__label {
  background: #d70f0a;
  padding: 20px 46px 20px 44px;
}

.content .form__row[data-step="4"] .form__label {
  color: #fff;
  background: #bac1ca;
  border-radius: 2px;
  margin-left: 16px;
  padding: 20px 46px 20px 44px;
  font-size: 16px;
  font-weight: 600;
}

.content .form__shadow {
  height: 100%;
  width: 100%;
  z-index: 99;
  background: #ffffffb3;
  display: block;
  position: absolute;
}

.content .form__step {
  width: 50px;
  height: 50px;
  z-index: 1;
  cursor: pointer;
  background: #fff;
  border: 2px solid #cdd2d9;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.content .form__step[data-active="1"] {
  background: #cdd2d9;
}

.content .form__step span {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.content .form__label {
  text-align: left;
  vertical-align: 18px;
  padding: 0 20px;
  display: inline-block;
}

.content .form__input {
  text-align: right;
  vertical-align: middle;
  text-align: right;
  float: right;
  width: 200px;
  margin-top: 12px;
  display: inline-block;
}

.content .form__input select, .content .form__input input {
  width: 100%;
  font-weight: normal;
}

.content .form__input input {
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
  padding: 5px 10px 5px 0;
}

.content .form__divider {
  height: 30px;
}

.content .form__divider[data-active="1"]:before {
  content: "";
  width: 1px;
  background: #cdd2d9;
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 25px;
}

.content .preview {
  margin-top: 10px;
  padding: 0 15px;
  position: relative;
}

.content .preview__pdf {
  min-height: 600px;
  display: block;
  position: relative;
}

.content .preview__pdf.with-border {
  border: 1px solid #bac1ca;
}

.content .preview__file {
  display: none;
  position: absolute;
}

.content .preview__file img {
  max-width: 100%;
  border: 1px solid #e3e6e5;
}

.content .preview__loading {
  height: 100%;
  width: 100%;
  z-index: 999;
  background: #0006;
  display: none;
  position: absolute;
  left: 0;
}

.content .preview__loading.load {
  display: block;
}

.content .preview__no-preview {
  color: #bac1ca;
  text-align: center;
  padding: 70px;
  font-size: 14px;
  line-height: 1.3;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

select[name="language"], select[name="format"] {
  display: none;
}

.dropdown {
  width: 100%;
  height: 30px;
  position: relative;
}

.dropdown.open .dropdown__container {
  display: block;
}

.dropdown.open .dropdown__icon-closed {
  display: none;
}

.dropdown.open .dropdown__icon-opened {
  display: block;
}

.dropdown__active-container {
  border-bottom: 1px solid #bac1ca;
  padding: 8px 5px;
  font-size: 16px;
  position: relative;
}

.dropdown__active-container:hover {
  cursor: pointer;
}

.dropdown__active-container:hover .dropdown__icon-closed {
  fill: #d70f0a;
}

.dropdown__title {
  width: 100%;
  text-align: left;
  padding-left: 15px;
  padding-right: 20px;
  display: inline-block;
}

.dropdown__container {
  width: 100%;
  text-align: left;
  z-index: 999999;
  background: #fff;
  border-radius: 2px;
  padding: 20px 14px 20px 15px;
  display: none;
  position: absolute;
  box-shadow: 0 8px 20px #535f6926;
}

.dropdown__icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.dropdown__icon-opened {
  display: none;
}

.dropdown__item {
  width: 100%;
  padding: 13px 15px;
  font-size: 16px;
  display: inline-block;
}

.dropdown__item.selected {
  font-weight: 600;
}

.dropdown__item.disabled {
  color: #a1aab5;
  cursor: default;
}

.dropdown__item:hover:not(.disabled) {
  cursor: pointer;
  color: #d70f0a;
  background: #f1f4f7;
}

.header {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 10px 0;
}

.header__content {
  color: #000;
  font-size: 52px;
  font-weight: 600;
  line-height: 50px;
  display: block;
}

.header__content span {
  font-weight: 200;
}

.header__image {
  text-align: right;
  margin-bottom: 30px;
  display: block;
}

.header__image img {
  width: 260px;
}

/*# sourceMappingURL=index.afa9b556.css.map */
